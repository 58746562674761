import React from "react";
import { Node } from "interweave";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";

/**
 * Convert inline styles like "text-decoration:none;color:white" to react style
 * objects: {textDecoration: 'none', color: 'white'}
 */
function inlineStyleToReactStyle(style: string) {
  const res: React.CSSProperties = {};
  style.split(";").forEach((cssProp) => {
    const propName = cssProp.split(":")[0];
    const propVal = cssProp.split(":").slice(1).join("");
    // Convert propName to title case
    const newPropName =
      propName.split("-")[0] +
      propName
        .split("-")
        .slice(1)
        .map((word) => word[0].toUpperCase() + word.slice(1));
    //@ts-ignore
    res[newPropName] = propVal;
  });
  return res;
}

/**
 * Get a dictionary of all the DOM attributes of given node.
 * The style atribute is converted to a React style object.
 * @param node
 * @returns
 */
export function getNodeAttributes(node: HTMLElement) {
  const res: {
    [key: string]: any;
  } = node.getAttributeNames().reduce(
    (obj, attr) => ({
      ...obj,
      [attr]: node.getAttribute(attr),
    }),
    {}
  );
  if ("style" in res) {
    // Convert element's inline style to react a style object
    res.style = inlineStyleToReactStyle(res.style);
  }
  return res;
}

export const RenderRichTextNode: React.FC<{ node: HTMLElement }> = ({
  node,
}) => {
  return (
    <div
      ref={(nodeEl) =>
        // We use replaceChild() here because Interweave internally keeps
        // track of this root div. replaceNode() would unmount this div and
        // Interweave will fail when it tries to delete this node.
        nodeEl && nodeEl.replaceChild(node, nodeEl.children[0])
      }
      style={{ display: "inline" }}
    >
      <div></div>
    </div>
  );
};

export function rootTransformer(
  node: HTMLElement,
  children: Node[]
): React.ReactNode {
  const tagName = node.tagName.toLowerCase();

  // Text formatting tags
  if (tagName === "a")
    return <MuiLink {...getNodeAttributes(node)}>{children}</MuiLink>;
  if (tagName === "p")
    return (
      <Typography
        variant="body1"
        fontSize="inherit"
        color="inherit"
        {...getNodeAttributes(node)}
      >
        {children}
      </Typography>
    );
  if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(tagName))
    return (
      <Typography
        variant={tagName as TypographyProps["variant"]}
        color="inherit"
        {...getNodeAttributes(node)}
      >
        {children}
      </Typography>
    );
  if (["strong", "bold"].includes(tagName))
    return (
      <Box
        fontWeight="fontWeightBold"
        display="inline"
        {...getNodeAttributes(node)}
      >
        {children}
      </Box>
    );
  if (tagName === "figcaption") {
    return (
      <Typography
        variant="caption"
        color="inherit"
        {...getNodeAttributes(node)}
      >
        {children}
      </Typography>
    );
  }

  // Lists
  if (["ul", "ol"].includes(tagName))
    return (
      <ul {...getNodeAttributes(node)}>
        <Typography variant="body1" fontSize="inherit" color="inherit">
          {children}
        </Typography>
      </ul>
    );
  if (tagName === "li")
    return (
      <li {...getNodeAttributes(node)}>
        <Typography variant="body1" fontSize="inherit" color="inherit">
          {children}
        </Typography>
      </li>
    );

  // Tables
  if (tagName === "table")
    return <Table {...getNodeAttributes(node)}>{children}</Table>;
  if (tagName === "thead")
    return <TableHead {...getNodeAttributes(node)}>{children}</TableHead>;
  if (tagName === "tbody")
    return <TableBody {...getNodeAttributes(node)}>{children}</TableBody>;
  if (tagName === "tr")
    return <TableRow {...getNodeAttributes(node)}>{children}</TableRow>;
  if (["td", "th"].includes(tagName))
    return <TableCell {...getNodeAttributes(node)}>{children}</TableCell>;

  // Misc
  if (tagName === "hr") return <Divider {...getNodeAttributes(node)} />;

  // Fallback to render node as-is
  return <RenderRichTextNode node={node} />;
}
