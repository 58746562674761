import React, { useState } from "react";
import Box from "@mui/material/Box";
import Interweave from "interweave";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { Node } from "interweave";
import { useMediaQuery, useTheme } from "@mui/material";

import { useIsomorphicLayoutEffect } from "@packages/common/src/custom-hooks/useAsync";

import BaseService from "../../service/baseService";
import { SERVER } from "../../config/utils";
import { NSNotification } from "./types";
import {
  getNodeAttributes,
  RenderRichTextNode,
} from "../rich-text-renderer/transformers";

export function rootTransformer(
  node: HTMLElement,
  children: Node[]
): React.ReactNode {
  const tagName = node.tagName.toLowerCase();

  if (tagName === "p")
    return (
      <Typography
        variant="body1"
        color="inherit"
        fontWeight={500}
        {...getNodeAttributes(node)}
      >
        {children}
      </Typography>
    );
  if (["strong", "bold"].includes(tagName))
    return (
      <Box
        fontWeight="fontWeightBold"
        display="inline"
        color="inherit"
        {...getNodeAttributes(node)}
      >
        {children}
      </Box>
    );

  // Fallback to render node as-is
  return <RenderRichTextNode node={node} />;
}

const baseService = new BaseService();
const NotificationBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [notification, setNotification] =
    useState<NSNotification.INotificationBar | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useIsomorphicLayoutEffect(() => {
    let mounted = true;
    if (!isLoaded) {
      baseService
        .get(`${SERVER}/api/notification-bar/`)
        .then((response) => {
          if (mounted) {
            if (response.data) {
              setNotification(response.data);
            }
            setIsLoaded(true);
          }
        })
        .catch((error) => console.error(error));
    }

    return () => {
      mounted = false;
    };
  }, []);

  return notification !== null ? (
    <Slide direction="down" in={true}>
      <Box
        sx={{
          bgcolor: "#4985e8",
          color: "#fff",
          borderRadius: 1,
          m: 0.25,
          p: 2,
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          columnGap: 2,
          a: {
            color: "#fff",
          },
        }}
      >
        {notification.tag && !isMobile && (
          <Chip
            label={notification.tag}
            sx={{
              backgroundColor: "#fff",
              color: "#4985e8",
              height: 26,
              fontSize: 14,
              "& .MuiChip-label": {
                px: 2,
              },
            }}
          />
        )}
        <Interweave
          content={
            isMobile
              ? notification.mobile_message
              : notification.desktop_message
          }
          transform={rootTransformer}
        />
        {notification.cta && !isMobile && (
          <Button
            variant="outlined"
            sx={{
              color: "#fff",
              border: "1px solid #fff",
              fontSize: 14,
              py: 0.75,
              px: 1.25,
              "&:hover": {
                color: "#4985e8 !important",
                background: "#fff !important",
              },
            }}
            href={notification.cta_link}
            target="_blank"
          >
            {notification.cta}
          </Button>
        )}
      </Box>
    </Slide>
  ) : (
    <></>
  );
};

export default NotificationBar;
